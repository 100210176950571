<script>
import Layout from "../../layouts/main";
import errorHandler from '../../../helpers/errorHandler';
import {
    FormWizard,
    TabContent,
    WizardButton
} from 'vue-form-wizard'

import { required, sameAs, minLength } from "vuelidate/lib/validators";
import userService from '../../../helpers/userService';


/**
 * Register component
 */
export default {
  page: {
    title: "Update Credentials",
  },
  name: "account-update-credentials",
  async created(){
    this.user1.user_name = await userService.methods.userUsername();
  },
  data() {
    return {
      user1: {
        user_name: "",
        password: "",
        passwordConfirm:"",
      },
      showPassword: "password",
      showPassword2: "password",
      submitted: false,
      regError: null,
      tryingToRegister: false,
      isRegisterError: false,
      registerSuccess: false,
      loadingWizard: false,
      fisrtStageDone: false, // Change after edit second tab
      secondStageDone: false, // Change after edit second tab
    
    };
  },
  components: {
    Layout,
    FormWizard,
    TabContent,
    WizardButton
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    notificationAutoCloseDuration() {
      return this.$store && this.$store.state.notification ? 5 : 0;
    },
  },
  validations: {
    user1: {
      user_name: {
        required,
      },
      password: {
        required,
        minLength : minLength(8)
      },
      passwordConfirm:{
        required,
        sameAsPassword: sameAs("password"),
      },
    },
  },
  methods: {
    async tryToRegisterIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.user1.$touch();
      if (this.$v.user1.$invalid) {
        return false;
      } else {
          this.tryingToRegister = true;
          // Reset the regError if it existed.
          // TODO :: Here request to get otp to user
          this.regError = null;
          return await this.$store
              .dispatch("user/updateCredentials", {
                user_name: this.user1.user_name,
                password: this.user1.password,
                otp: '0000'
              })
              // eslint-disable-next-line no-unused-vars
              .then(async token => {
                this.tryingToRegister = false;
                this.isRegisterError = false;
                this.registerSuccess = true;
                return true;
              })
              .catch((error) => {
                errorHandler.methods.errorMessage(error)
                this.tryingToRegister = false;
                this.regError = (error.response.data.detail["error message"])?error.response.data.detail["error message"]:error.response.data.detail.errors[0];
                this.isRegisterError = true;
              });
      }
    },
    togglePassword(){
      if(this.showPassword == "password"){
        this.showPassword = "text";
      }else{
        this.showPassword = "password";
      }
    },
    togglePassword2(){
      if(this.showPassword2 == "password"){
        this.showPassword2 = "text";
      }else{
        this.showPassword2 = "password";
      }
    },
    setLoading(value){
      this.loadingWizard = value;
    },
    goLogin(){
      this.$router.push({
        path:'/login'
      })
    }
  },
};
</script>

<template>
  <Layout>
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-8 col-xl-8">
        <div class="card">
          <div class="card-body p-4">
            <div class="text-center w-75 m-auto">
              <div class="auth-logo">
                <router-link to="/" class="logo logo-dark text-center">
                  <span class="logo-lg">
                    <img src="@/assets/images/logo-dark.png" alt height="120" />
                  </span>
                </router-link>

                <router-link to="/" class="logo logo-light text-center">
                  <span class="logo-lg">
                    <img src="@/assets/images/logo-light.png" alt height="120" />
                  </span>
                </router-link>
              </div>
              <p class="text-muted mb-4 mt-3">
                Change your username or password from here
              </p>
            </div>
            <!-- @on-complete="null"-->
             <form-wizard color="#3bafda" @on-loading="setLoading" :startIndex="(this.$route.params.id)?1:0">
              <b-alert
                v-model="registerSuccess"
                class="mt-3"
                variant="success"
                dismissible
                >Registration successfull.</b-alert
              >

              <b-alert
                v-model="isRegisterError"
                class="mt-3"
                variant="danger"
                dismissible
                :show="notificationAutoCloseDuration"
                >{{ regError }}</b-alert
              >

              <b-alert
                :variant="notification.type"
                class="mt-3"
                v-if="notification.message"
                :show="notificationAutoCloseDuration"
                dismissible
                >{{ notification.message }}</b-alert
              >
              <tab-content icon="mdi mdi-account-circle" :before-change="tryToRegisterIn" title="User Credentials" v-if="!fisrtStageDone">
                <form action="#" v-if="!loadingWizard">
                    <div class="row">
                      <div class="col-sm-12 col-xl-6">
                        <div class="form-group">
                          <label for="fullname">Username</label>
                          <input
                            class="form-control"
                            v-model="user1.user_name"
                            type="text"
                            id="fullname"
                            placeholder="Enter your login username"
                            :class="{
                              'is-invalid': submitted && $v.user1.user_name.$error,
                            }"
                          />
                          <div
                            v-if="submitted && !$v.user1.user_name.required"
                            class="invalid-feedback"
                          >
                            Username is required.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-12 col-xl-6">

                        <div class="form-group">
                          <label for="password">New Password</label>
                          <div class="input-group input-group-merge">
                            <input
                              :type="showPassword"
                              v-model="user1.password"
                              id="password"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.user1.password.$error,
                              }"
                              placeholder="Enter your new password"
                            />
                            <div class="input-group-append" data-password="false" @click="togglePassword">
                              <div class="input-group-text">
                                <span class="password-eye"></span>
                              </div>
                            </div>
                            <div
                              v-if="submitted && !$v.user1.password.required"
                              class="invalid-feedback"
                            >
                              New Password is required.
                            </div>
                            <div
                              v-if="submitted && !$v.user1.password.minLength"
                              class="invalid-feedback"
                            >
                              New Password is too short at least 8 characters.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-xl-6">
                        <div class="form-group">
                          <label for="password">Confirm New Password</label>
                          <div class="input-group input-group-merge">
                            <input
                              :type="showPassword2"
                              v-model="user1.passwordConfirm"
                              id="password"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.user1.passwordConfirm.$error,
                              }"
                              placeholder="Enter your new password"
                            />
                            <div class="input-group-append" data-password="false" @click="togglePassword2">
                              <div class="input-group-text">
                                <span class="password-eye"></span>
                              </div>
                            </div>
                            <div
                              v-if="submitted && !$v.user1.passwordConfirm.required"
                              class="invalid-feedback"
                            >
                              New Password is required.
                            </div>
                            <div
                              v-if="submitted && !$v.user1.passwordConfirm.sameAsPassword"
                              class="invalid-feedback"
                            >
                              New Password is not matched.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    </form>
              </tab-content>
              <tab-content icon="mdi mdi-checkbox-marked-circle-outline" title="Finish!" :before-change="goLogin">
                  <div class="row">
                      <div class="col-12">
                          <div class="text-center">
                              <h2 class="mt-0">
                                  <i class="mdi mdi-check-all"></i>
                              </h2>
                              <h3 class="mt-0">Thank you !</h3>

                              <p class="w-75 mb-2 mx-auto">
                                  Congrats, your credentials are updated successfully !
                              </p>

                              
                          </div>
                      </div>
                      <!-- end col -->
                  </div>
                  <!-- end row -->
              </tab-content>
              <div class="loader" v-if="loadingWizard"></div>
              <template slot="footer" slot-scope="props">
                <div class="wizard-footer-left">
                  <!-- remove previous button -->
                  <!-- <wizard-button v-if="props.activeTabIndex > 0 && !props.isLastStep" @click.native="props.prevTab()" :style="props.fillButtonStyle">Previous</wizard-button> -->
                </div>
                <div class="wizard-footer-right">
                  <wizard-button @click.native="props.nextTab()" class="wizard-footer-right finish-button " :style="props.fillButtonStyle">{{props.isLastStep ? 'Login' : 'Next'}}</wizard-button>
                </div>
              </template>
          </form-wizard>

          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->

        <!-- end row -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>

<style scoped>
/* This is a css loader. It's not related to vue-form-wizard */
.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #3bafda;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
